<template>
  <div
    class="recommendations-container"
    :class="{
      'u-display-none': isCustomActionStateVisible || !nonZeroRecs.length
    }"
  >
    <widgetContainer
      :header-options="headerOptionsComp"
      :footer-options="footerOptionsComp"
      :context="context"
      :last-updated-date="lastUpdatedDate"
      :title="(metadata || {}).label"
      :description="(metadata || {}).description"
      :is-loading="isLoading"
      :header-container-grid-styles="headerContainerGridStyles"
    >
      <div
        slot="body"
        :class="{ isLoading: isLoading }"
      >
        <div
          v-if="Object.keys(computedSelectedRecommendation).length"
          class="u-display-flex u-flex-direction-column u-flex-align-items-start u-spacing-pt-l header"
        >
          <div
            class="u-display-flex u-flex-align-items-center u-spacing-ph-l u-spacing-pb-s"
            :class="{ 'u-spacing-mh-xl': !recCustomComponent.ui_component }"
          >
            <span
              class="u-font-size-6 u-color-grey-lighter u-font-weight-400 text-underline u-cursor-pointer"
              @click="resetFilter"
            >
              Recommendations
            </span>
            <rb-icon
              class="rb-icon--small u-spacing-mh-xs u-color-grey-lighter u-font-weight-600"
              :icon="'arrow-right'"
            />
          </div>
          <div
            v-if="!recCustomComponent.ui_component"
            class="u-display-flex u-flex-align-items-center u-spacing-mh-l"
          >
            <span @click="resetFilter">
              <rb-icon
                icon="arrow_back"
                class="u-color-grey-lighter rb-icon--x-medium u-cursor-pointer u-spacing-mr-s"
              />
            </span>
            <p
              class="u-color-grey-lighter u-font-size-3 u-text-case-upper u-font-weight-600"
            >
              <span class="u-color-grey-base">{{
                computedSelectedRecommendation[countKey]
              }}</span>
              {{
                computedSelectedRecommendation[countKey]
                  ? `${entityName}s`
                  : entityName
              }}
              with recommendations in
              <span class="u-color-grey-base"
                >'{{ computedSelectedRecommendation[labelKey] }}'</span
              >
            </p>
          </div>

          <component
            :is="recCustomComponent.ui_component"
            v-if="recCustomComponent.ui_component"
            :class="recCustomComponent.class"
            v-bind="recCustomComponent.props"
            v-on="eventMaper(recCustomComponent.events)"
          />
        </div>
        <section
          v-if="!Object.keys(selectedRecommendation).length"
          class="u-spacing-mt-l u-spacing-ph-l header"
        >
          <icon-with-text
            class=""
            :text="headingName"
            :icon="'alerts'"
            :text-classes="'u-font-size-3 u-font-weight-600 u-color-grey-lighter'"
          />

          <div class="rec-card-container">
            <div
              v-for="(card, index) of content"
              :key="index"
              class="card rec-card"
            >
              <component
                :is="component.name"
                v-for="(component, i) of card.components"
                :key="i"
                :class="component.class"
                v-bind="component.props"
                v-on="eventMaper(component.events)"
              >
                {{ component.text }} {{ component.textToAppend }}
              </component>
            </div>
          </div>
        </section>
      </div>
    </widgetContainer>
  </div>
</template>
<script>
import widgetContainer from '@/components/widgets/custom_widgets/cw_container.vue';
import widgetsUtil from '@/components/widgetMixin';
import cwMixin from '@/components/widgets/custom_widgets/cw_mixin.js';
import isEmpty from 'lodash/isEmpty';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
const dashUtils = require('@/utils/common/dashboard-service-utils.js');

const rbButtonWrapper = {
  name: 'RbButtonWrapper',
  props: {
    text: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: ''
    },
    classes: {
      type: String,
      default: ''
    },
    metricName: {
      type: String,
      default: ''
    },
    metricValue: {
      type: String,
      default: ''
    }
  },
  methods: {
    handleClick() {
      this.$emit('click', {
        [this.metricName.replace(':', '')]: this.metricValue
      });
    }
  },
  template: `
    <rb-button
    :text="text"
    :size="size"
    :type="type"
    :class="classes"
    :click-fn="handleClick"
    />
  `
};
function mapValuesToProps(compProps, mapMetricNameToKey) {
  const props = {};
  Object.keys(compProps).forEach((elem) => {
    const metric = mapMetricNameToKey[compProps[elem]];
    props[elem] = compProps[elem];
    if (!(metric === null || metric === undefined)) {
      props[elem] = metric;
    }
    if (elem === 'props') {
      props.props = mapValuesToProps(compProps.props, mapMetricNameToKey);
    }
    if (!(props.text === null || props.text === undefined)) {
      props.text = String(props.text);
    }
    // if (compProps.parser) {
    //   if (compProps.parser[elem]) {
    //     props[elem] = utils[compProps.parser[elem]](props[elem]);
    //   }
    // }
  });
  return props;
}
// const obj = {
// 'dsp_recommendation_metadata_order_count': {
//   name: 'iconWithText',
//   class: 'count-class',
//   props: {
//     text: 'dsp_recommendation_metadata_order_count',
//     textToAppend: 'recommendation',
//     icon: 'logo',
//     iconSize: 'medium',
//     iconClasses: 'u-color-blue-base',
//     textClasses: 'count-icon-text-class'
//   }
// },
// 'dsp_recommendation_metadata_line_item_count': {
//   name: 'iconWithText',
//   class: 'count-class',
//   props: {
//     text: 'dsp_recommendation_metadata_item_count',
//     textToAppend: 'recommendation',
//     icon: 'logo',
//     iconSize: 'medium',
//     iconClasses: 'u-color-blue-base',
//     textClasses: 'count-icon-text-class'
//   }
// },
// 'dsp_recommendation_metadata_rec_label': {
//   name: 'h2',
//   text: 'dsp_recommendation_metadata_rec_label',
//   class: 'rec-label-class'
// },
// 'dsp_recommendation_metadata_rec_key_workbench': {
//   name: 'rb-button-wrapper',
//   text: 'View recommendation',
//   class: 'action-button',
//   props: {
//     text: 'View recommendation',
//     size: 's',
//     type: 'hollow',
//     classes: 'u-color-blue-base',
//     metricName: ':dsp_recommendation_metadata_rec_key',
//     metricValue: 'dsp_recommendation_metadata_rec_key'
//   },
//   events: {
//     click: 'handleClick'
//   }
// 'dsp_recommendation_metadata_rec_key': {
//   name: 'rb-button-wrapper',
//   text: 'dsp_recommendation_metadata_rec_label',
//   class: 'action-button',
//   props: {
//     text: 'View recommendation',
//     size: 's',
//     type: 'hollow',
//     classes: 'u-color-blue-base',
//     metricName: ':dsp_recommendation_metadata_rec_key',
//     metricValue: 'dsp_recommendation_metadata_rec_key'
//   },
//   events: {
//     click: 'handleClick'
//   }

// },
// 'dsp_recommendation_metadata_rec_desc': {
//   name: 'span',
//   text: 'dsp_recommendation_metadata_rec_desc',
//   class: 'rec-description-class'
// }
// };

export default {
  name: 'AltRecommendationOverview',
  components: {
    widgetContainer,
    rbButtonWrapper
  },
  mixins: [widgetsUtil, cwMixin],
  props: {
    headerOptions: {
      type: Array,
      default: () => []
    },
    enableFooter: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: null
    },
    description: {
      type: String,
      default: null
    },
    context: {
      type: Object,
      default: () => {
        return {};
      }
    },
    headerContainerGridStyles: {
      type: Object,
      default: () => {
        return {
          'grid-template-columns': '2.4rem 1fr auto',
          'align-items': 'end'
        };
      }
    },
    countKey: {
      type: String,
      default: 'dsp_recommendation_metadata_order_count'
    },
    entityName: {
      type: String,
      default: 'order'
    },
    labelKey: {
      type: String,
      default: 'dsp_recommendation_metadata_rec_label'
    },
    recCustomComponent: {
      type: Object,
      default() {
        return {};
      }
    },
    selectedRecommendation: {
      type: Object,
      default() {
        return {};
      }
    },
    isCustomActionStateVisible: {
      type: Boolean,
      default: false
    },
    headingName: {
      type: String,
      default: 'RECOMMENDATIONS OVERVIEW'
    },
    // eslint-disable-next-line vue/prop-name-casing
    APIConfig: {
      type: Object,
      default() {
        return {
          endpoint: '',
          request: {},
          service: ''
        };
      }
    }
  },
  data() {
    return {
      isLoading: true,
      entityData: [],
      metadataKeyMap: {
        key: 'recKey',
        label: 'recLabel',
        desc: 'recDesc',
        count: 'count'
      },
      metricsMetadata: {}
    };
  },
  computed: {
    nonZeroRecs() {
      // hide recommendation widget if rec count for all cards are 0
      return (
        this.entityData?.filter((item) =>
          Object.values(item)?.every((val) => val !== 0 && val !== undefined)
        ) || []
      );
    },
    content() {
      // metadata driven dynamically rendered components inside card such as heading, sub-heading, button etc.
      if (Object.keys(this.metricsMetadata).length && this.nonZeroRecs.length) {
        // filter out rec-cards where recommendations are 0
        return this.nonZeroRecs?.map((entity) => {
          return {
            components: Object.keys(entity)
              .map((key) => {
                const newObj =
                  this.metricsMetadata?.[key]?.metadata &&
                  mapValuesToProps(this.metricsMetadata[key].metadata, entity);
                return newObj;
              })
              .filter(Boolean)
          };
        });
      }
    },
    computedSelectedRecommendation() {
      if (
        Object.keys(this.selectedRecommendation).length &&
        this.entityData.length
      ) {
        return (
          this.entityData.find(
            (entity) =>
              this.selectedRecommendation[this.labelKey] ===
              entity[this.labelKey]
          ) || {}
        );
      } else {
        return {};
      }
    }
  },
  watch: {
    widgetRequestParams(newVal, oldVal) {
      if (!isEqual(newVal, oldVal)) {
        this.fetchData();
      }
    }
  },
  created() {
    // transform metric keys to the keys received from response
    const transformedMetrics = {};
    const metrics = cloneDeep(this.metadata.metrics);
    Object.entries(metrics).forEach(([metricName, metricValue]) => {
      const newMetadataKeys = Object.keys(this.metadataKeyMap);
      for (const metadataKey of newMetadataKeys) {
        if (metricName.includes(metadataKey)) {
          transformedMetrics[this.metadataKeyMap[metadataKey]] = metricValue;
          break;
        }
      }
    });
    this.metricsMetadata = transformedMetrics;
    this.fetchData();
  },
  methods: {
    fetchData() {
      try {
        this.isLoading = true;
        let request;
        // ignore the api template from the metric metadata and make the call to service sent as prop
        const api = cloneDeep(this.APIConfig);
        request = api.request;
        request = dashUtils.replacePlaceHolderWithData(
          request.brandsRequest,
          this.widgetRequestParams
        );
        request.where.dimensionNameValueList =
          this.widgetRequestParams[':dimensionNameValueList'];

        delete request.where.pvpDate;

        request = {
          brandsRequest: request,
          entityType: this.APIConfig.request?.entityType
        };
        dashUtils.fetchDataAPI(request, api).then((args) => {
          this.entityData = args;
          this.isLoading = false;
        });
      } catch (error) {
        console.log(error);
        this.$snackbar.open({
          message: 'Something Went Wrong',
          duration: 6000,
          actionText: ''
        });
      }
    },
    handleClick(ev) {
      const [value] = Object.values(ev);
      const selectedRecommendation = this.entityData.find((item) => {
        const values = Object.values(item);
        return values.includes(value);
      });
      this.$emit(
        'recommendationFilter',
        ev,
        selectedRecommendation[this.labelKey]
      );
      this.$emit('handleRecommendation', selectedRecommendation);
    },
    eventMaper(eventMap) {
      if (isEmpty(eventMap)) {
        return {};
      }
      const vueEventMap = {};
      const parentReference = this;
      Object.keys(eventMap).forEach((listenerKey) => {
        if (typeof eventMap[listenerKey] === 'function') {
          vueEventMap[listenerKey] = eventMap[listenerKey];
        } else {
          vueEventMap[listenerKey] = parentReference[eventMap[listenerKey]];
        }
      });
      return vueEventMap;
    },
    resetFilter() {
      this.$emit('toggleRecCustomComponent', false);
      this.$emit('handleSelectionChange', []);
      this.$emit('recommendationFilter', '');
      this.$emit('handleRecommendation', {});
    }
  }
};
</script>

<style lang="css">
.recommendations-container .isLoading {
  min-height: 40vh;
}
.recommendations-container .custom_widget_container {
  background: white;
}
.recommendations-container .header-container-cwc {
  padding: 2.4rem;
  display: grid;
}
.recommendations-container .header-container-cwc .custom-color {
  color: #ffa800;
  border: solid 1px #ffa800;
}
.recommendations-container .header-container-cwc .custom-color:hover {
  color: #dd9305;
  border: solid 1px #dd9305;
}
.recommendations-container .rec-card-container {
  padding-top: 2.4rem;
  padding-bottom: 2.4rem;
  display: flex;
  flex-wrap: wrap;
  gap: 2.4rem;
  box-sizing: border-box;
}
.recommendations-container .rec-card-container .rec-card {
  display: flex;
  flex-direction: column;
  padding: 1.6rem;
  width: 26rem;
  justify-content: space-around;
  box-shadow: 0 0 4px 0 #caccce;
}
.recommendations-container .rec-card-container .rec-card .action-button {
  order: 5;
}
.recommendations-container .rec-card-container .rec-card .rb-button--hollow {
  color: #007cf6 !important;
  border: 1px solid #007cf6 !important;
  width: max-content;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  padding-left: 1.6rem;
  padding-right: 1.6rem;
}
.recommendations-container
  .rec-card-container
  .rec-card
  .rb-button--hollow:hover {
  color: #fff !important;
  border: 1px solid #007cf6 !important;
  background: #007cf6 !important;
}
.recommendations-container .rec-card-container .rec-card .count-class {
  margin-bottom: 1.6rem;
}
.recommendations-container .rec-card-container .rec-card .count-icon-class {
  color: #007cf6;
}
.recommendations-container
  .rec-card-container
  .rec-card
  .count-icon-text-class {
  font-size: 1.4rem;
  color: #000;
  font-weight: 600;
}
.recommendations-container .rec-card-container .rec-card .textClass {
  padding-top: 0.3rem;
}
.recommendations-container .rec-card-container .rec-card .rec-label-class {
  font-size: 1.3rem;
  margin-bottom: 0.8rem;
}
.recommendations-container .rec-card-container .rec-card .rec-description-class,
.recommendations-container .rec-card-container .rec-card .description {
  line-height: 1.2;
  font-size: 1.1rem;
  margin-bottom: 1.6rem;
}
.recommendations-container .text-underline {
  text-decoration: underline;
}
</style>
